import React from 'react'

import Sedan from '../components/sedan'
import HalfImg from '../components/HalfImg'
import Gallery from '../components/Gallery'
import { miasta as miastaDetailed } from '../utils/data-detailed'
import { KILOMETROWKA, BASE_PRICE_ENDOSKOPIA, BASE_PRICE } from '../utils/cena'
import reviews from '../icons/reviews.svg'
import ContactTopBar from '../components/ContactTopBar'
import Layout from '../components/Layout'
import { Miasta } from '../components/Miasta'
import Raport from '../components/Raport'
import { graphql, StaticQuery } from 'gatsby'
import { BackToHome } from '../components/BackToHome'

export const pageQuery = graphql`
  query X {
    raport: markdownRemark(frontmatter: { title: { eq: "raport" } }) {
      html
    }
  }
`

class IndexPage extends React.Component {
  constructor() {
    super()
    this.state = { allCitiesVisible: false }
    this.displayAllCities = this.displayAllCities.bind(this)
  }

  componentDidMount() {
    // gatsby own scroll mess with this
    if (typeof window !== 'undefined') {
      if (window.scrollY) {
        window.scroll(0, 0)
      } else if (window.scrollTo) {
        window.scrollTo(0, 0)
      }
    }
  }

  displayAllCities = () => {
    this.setState({ allCitiesVisible: !this.state.allCitiesVisible })
  }

  render() {
    return (
      <StaticQuery
        query={pageQuery}
        render={data => {
          return (
            <Layout>
              <div>
                <div className="row img-section" id={'main'}>
                  <div className="overlay-effect" />

                  <div className="col-12 img-section-content">
                    <ContactTopBar />
                    <div style={{ justifyContent: 'center' }}>
                      <a href="/">
                        <Sedan size={64} />
                      </a>
                      <h1>Auto Test 360 - Marian Gibała</h1>
                      <h2>Sprawdzenie samochodu przed zakupem - Śląsk</h2>
                      <h3 id="miasta">
                        <span>Inspekcja</span>
                        <span>Diagnostyka komputerowa</span>
                        <span>Kontrola techniczna auta</span>
                      </h3>
                    </div>

                    <div className="content">
                      <p>
                        Sprzedawcy zawsze przedstawiają używane samochody w jak
                        najlepszym świetle i bez odpowiedniej wiedzy,
                        doświadczenia oraz sprzętu, ciężko jest mieć pewność, że
                        prezentowany opis jest zgodny z rzeczywistością.
                      </p>

                      <p>
                        Korzystając z usługi dowiesz się jaki jest rzeczywisty
                        stan techniczny samochodu. Moja weryfikacja pomoże Ci
                        podjąć decyzję o kupnie auta i uniknąć niezadowolenia
                        oraz zbędnych wydatków. Przekonało się o tym już mnóstwo
                        klientów, którzy nie żałują skorzystania z mojej pomocy.
                      </p>

                      <p>
                        Do każdego samochodu podchodzę indywidualnie, począwszy
                        od sprawdzenia nr VIN, a kończąc na dokładnych
                        oględzinach samochodu na miejscu. Jestem niezależną
                        stroną między sprzedawcą a nabywcą, obiektywnie i
                        bezstronnie oceniając stan oglądanych samochodów.
                      </p>
                    </div>
                  </div>
                </div>

                <HalfImg imgId="img-3" imgRight={true}>
                  <h3>Oferta - Sprawdzenie samochodu przed zakupem</h3>

                  <p>
                    Przedstawię Ci obiektywnie stan samochodu oraz przybliżone
                    koszty, które trzeba ponieść. Wszystkie elementy, mające
                    istotny wpływ na stan auta zostaną sfotografowane. Obszerna
                    fotorelacja z oględzin pozwoli Ci na wnikliwe obejrzenie i
                    sprawdzenie samochodu bez wychodzenia z domu.
                  </p>

                  <p>
                    Proces, który oferuję, jest przejrzysty i składa się z kilku
                    prostych kroków.
                  </p>

                  <div className="process-item">
                    <div className="process-num">
                      <span>1</span>
                    </div>
                    <p>
                      Skontaktuj się ze mną w celu ustalenia detali oraz terminu
                      inspekcji. Dane ogłoszenia, które Cię zainteresowało
                      możesz wysłać na adres email kontakt@autotest360.pl.
                      <br />
                      <br />
                      <span className="bold">
                        Jaki jest koszt inspekcji?
                      </span>{' '}
                      <br />
                      <br />
                      <span className="cena"> {BASE_PRICE}zł</span> + koszty
                      dojazdu ({KILOMETROWKA}zł za każdy kilometr od Gliwic,
                      przejazd przez bramki na autostradzie) + opcjonalnie,
                      jeżeli auto jest w dobrym stanie i wyrazisz taką chęć,
                      mogę udać się na stację kontroli pojazdów (+130zł).
                      <br />
                      <br />
                      <span>Przybliżony cennik, inspekcja + dojazd:</span>
                      <Miasta
                        priceType="price"
                        miasta={miastaDetailed.filter(m => m.displayInPricing)}
                        sort="PRICE"
                      />
                      <br />
                      <span
                        role="button"
                        style={{ cursor: 'pointer' }}
                        onClick={this.displayAllCities}
                      >
                        Zobacz pozostałe miasta ->
                      </span>
                      {this.state.allCitiesVisible && (
                        <Miasta
                          priceType="price"
                          sort="NAME"
                          miasta={miastaDetailed.filter(
                            m => !m.displayInPricing
                          )}
                        />
                      )}
                    </p>
                  </div>

                  <div className="process-item">
                    <div className="process-num">
                      <span>2</span>
                    </div>
                    <p>
                      Oględzin samochodu mogę dokonać w Twojej obecności, lub
                      samodzielnie - w zależności od Twoich preferencji. Jeżeli
                      oględzin dokonuję sam, zadzownię do Ciebie i przedstawię
                      ocenę techniczną pojazdu telefonicznie.
                    </p>
                  </div>

                  <div className="process-item">
                    <div className="process-num">
                      <span>3</span>
                    </div>
                    <p>Podejmij świadomą decyzję dotyczącą zakupu.</p>
                  </div>
                </HalfImg>
                <Gallery />
                <Raport data={data.raport} />
                <BackToHome />
              </div>
            </Layout>
          )
        }}
      />
    )
  }
}

export default IndexPage
