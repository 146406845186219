import React from 'react'

function Raport({ data }) {
  return (
    <div id="section-raport" className="row section">
      <div className="col-12 section-content">
        <div style={{ justifyContent: 'center' }}></div>

        <div className="content-wide">
          <div dangerouslySetInnerHTML={{ __html: data.html }}></div>
        </div>
      </div>
    </div>
  )
}

export default Raport
