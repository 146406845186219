import React, { useEffect } from 'react'

import img1 from '../img/1.jpg'
import img2 from '../img/2.jpg'
import img3 from '../img/3.jpg'
import img4 from '../img/4.jpg'
import img5 from '../img/5.jpg'
import imgThermo from '../img/termo_s.jpg'
import diagnostykaCzujnikow from '../img/diagnostyka-czujnikow.jpg'
import img6 from '../img/6.jpg'

import img1webp from '../img/1.webp'
import img2webp from '../img/2.webp'
import img3webp from '../img/3.webp'
import img4webp from '../img/4.webp'
import img5webp from '../img/5.webp'
import imgThermoWebp from '../img/termo_s.webp'
import imgDiagnostykaCzujnikowWebp from '../img/diagnostyka-czujnikow.webp'
import img6webp from '../img/6.webp'

function Raport({ data }) {
  return (
    <div id="section-gallery" className="row section">
      <div className="col-12 section-content">
        <div className={'gallery'} style={{ justifyContent: 'center' }}>
          <picture>
            <source srcSet={img2webp} type="image/webp" />
            <source srcSet={img2} type="image/jpeg" />
            <img
              title="Sprzęt diagnostyczny"
              alt="Sprawdzenie auta"
              src={img2}
            />
          </picture>

          <picture>
            <source srcSet={img1webp} type="image/webp" />
            <source srcSet={img1} type="image/jpeg" />
            <img
              title="Sprzęt diagnostyczny"
              alt="Sprawdzenie auta"
              src={img1}
            />
          </picture>

          <picture>
            <source srcSet={img3webp} type="image/webp" />
            <source srcSet={img3} type="image/jpeg" />
            <img
              title="Sprzęt diagnostyczny"
              alt="Sprawdzenie auta"
              src={img3}
            />
          </picture>

          <picture>
            <source srcSet={img4webp} type="image/webp" />
            <source srcSet={img4} type="image/jpeg" />
            <img title="Miernik lakieru" alt="Sprawdzenie lakieru" src={img4} />
          </picture>

          <picture>
            <source srcSet={img5webp} type="image/webp" />
            <source srcSet={img5} type="image/jpeg" />
            <img
              title="Komputer diagnostyczny"
              alt="Diagnostyka komputerowa"
              src={img5}
            />
          </picture>

          <picture>
            <source srcSet={imgThermoWebp} type="image/webp" />
            <source srcSet={imgThermo} type="image/jpeg" />
            <img
              title="Diagnostyka termowizyjna"
              alt="Diagnostyka termowizyjna"
              src={imgThermo}
            />
          </picture>
          
        </div>
      </div>
    </div>
  )
}

export default Raport
